.content-col {
  //box-shadow: 1px 1px 2px rgba(100, 100, 100, 0.7);
}
/*Mobile Responsive*/
@media only screen and (max-width: 768px) {
  .content-col {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
