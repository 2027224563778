.portfolio {
  padding: 0px 30px;
}

.portfolio .portfolio-panel canvas {
  height: 250px !important;
}
.portfolio .distribution-panel canvas {
  height: 263px !important;
}
.portfolio .bottom-row {
  min-height: 800px !important;
  margin-top: 20px !important;
}

@media screen and (min-height: 1440px) {
  .portfolio .portfolio-panel canvas {
    height: 450px !important;
  }
  .portfolio .distribution-panel canvas {
    //height: 400px !important;
  }
}

@media screen and (min-height: 2000px) {
  .portfolio .portfolio-panel canvas {
    height: 650px !important;
  }
  .portfolio .distribution-panel canvas {
    height: 600px !important;
  }
}
