.free-info {
  padding: 20px;
}

.free-info .item-check {
  color: #05cd99;
  font-size: 12px;
  margin-right: 10px;
}

.free-info .header {
  padding-top: 10px;
  padding-bottom: 10px;
}

.free-info .header-row {
  margin-top: 10px;
  // color: #2b3674;
}

.free-info .text-row {
  margin-top: 20px;
  color: #8a92a6;
}

.free-info .btn-row {
  margin-top: 40px;
  margin-bottom: 20px;
}

.free-info h1 {
  font-size: 30px !important;
}

.free-info h6 {
  font-size: 15px !important;
}

@media only screen and (min-height: 1440px) {
  .free-info .header {
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .free-info .header-row {
    margin-top: 15px;
  }

  .free-info .text-row {
    margin-top: 25px;
  }
}

@media only screen and (min-height: 2000px) {
  .free-info .header {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .free-info .header-row {
    margin-top: 20px;
  }

  .free-info .text-row {
    margin-top: 40px;
  }
}
