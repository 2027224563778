.refer-now {
  background-image: url(/images/bg2.png);
  background-repeat: no-repeat;
  background-size: 760px 570px;
  background-position: right 450px;
  min-height: 1000px;
  width: 100%;
  display: flex;
  color: black;
  font-size: 14px;
}

.refer-now .left {
  flex: 1.3;
  padding-left: 140px;
  padding-top: 50px;
}

.refer-now .header1 {
  color: #002255;
  font-size: 48px;
  font-weight: bold;
  font-style: italic;
  margin-bottom: 0px;
}

.refer-now .header2 {
  color: #73cee2;
  font-size: 36px;
  font-weight: bold;
  margin-top: 30px;
}

.refer-now .btn {
  background-color: #73cee2;
  border: none;
  color: white;
  font-size: 18px;
  font-weight: bold;
  height: 44px;
  border-radius: 22px;
  padding-left: 40px;
  padding-right: 40px;
}

.refer-now .code-section {
  margin-top: 30px;
  display: flex;
}

.refer-now .code-section div {
  margin-right: 70px;
  text-align: center;
}

.refer-now .code-section .link {
  padding-top: 50px;
}

.refer-now .code-section .link .code {
  margin-top: 30px;
  background-color: white;
  color: black;
  border: solid 1px #73cee2;
  width: 100%;
}

.refer-now .header3 {
  font-weight: bold;
}

.refer-now .frag1 {
  margin-top: 40px;
}

.refer-now .frag2 {
  font-weight: bold;
  margin-top: 40px;
}

.refer-now .frag3 {
  margin-top: 40px;
}

.refer-now .facebook {
  background-color: #104a8d;
  margin-right: 20px;
}

.refer-now .btn {
  padding-top: 11px;
}

.refer-now .social {
  font-size: 16px;
  margin-right: 10px;
}

.refer-now .right {
  flex: 1;
  padding-left: 80px;
}

/*Mobile Responsive*/
@media only screen and (max-width: 768px) {
  .refer-now {
    background-image: none;
  }
  .refer-now .right {
    display: none;
  }

  .refer-now .left {
    padding-left: 20px;
    padding-right: 20px;
  }

  .refer-now .header1 {
    font-size: 36px;
  }

  .refer-now .header2 {
    font-size: 20px;
  }

  .refer-now .code-section div {
    margin-right: 30px;
  }

  .refer-now .btn {
    margin-top: 10px;
  }
}
