



.data-page .answer-box {
  background: "#fffdfc";
  padding: 15;
  border-radius: 3;
}


