.terminal {
  padding: 0px 30px;
}

.panel {
  padding: 10px;
}

.trade-panel {
  max-width: 300px;
  padding: 10px !important;
  margin-left: 20px;
}

.watch-panel {
  max-width: 280px;
}



@media screen and (min-height: 1440px) {
  .trade-panel {
    max-width: 400px;
    background-color: white;
    border: solid 1px lightgray;
    padding: 10px;
  }

  .watch-panel {
    max-width: 380px;
  }
}
